const { REACT_APP_ENV } = process.env;
export const ocrTypes = {
    hv: 'HV OCR',
    googlejs: 'GoogleJS OCR',
    google: 'Google OCR',
    azure: 'Azure OCR',
}

export const regions = REACT_APP_ENV === "prod" ? {
    "ind": "Mumbai",
    "sgp": "Singapore",
    "usa": "N. Virginia"
} : {
    "stg": "STAGING",
    "dev": "DEV",
}

export const defaultRegion = REACT_APP_ENV === 'prod' ? 'ind' : 'stg';

export const getSelectedRegion = () => {
    try {
        const localStorageRegion = localStorage.getItem('REGION');
        if (localStorageRegion && regions[localStorageRegion]) return localStorageRegion;
        localStorage.setItem('REGION', defaultRegion);
        return defaultRegion;
    }
    catch {
        return defaultRegion;
    }
};

export const getCurrentInferenceDomain = () => {
    const selectedRegion = getSelectedRegion();
    return `${selectedRegion}-turing.hyperverge.co`;
}